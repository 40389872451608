<script>
import Layout from '../../../layout/Layout'
import Menu from '../menu'
import {list} from '@/domain/gerencia/clientes/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import StatusCliente from './include/StatusCliente'
import ContextMenuClienteItem from './context/list-context'
import MenuOptions from '../../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
import SearchFilter from '../../../layout/helpers/SearchFilter'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import StatusSelect from './include/StatusClienteSelect'
import DateInput from '../../../../reuse/input/Date'
import clienteWindow from '../windows/cliente'
import PlanoSelect from './include/PlanoSelect'
// import UfSelect from '../../pessoa/components/include/UfSelect'
import {datePtToEn} from '@/utils/date'
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"

let filters = {
  id: '',
  plano: '',
  search: '',
  documento: '',
  data1: '',
  data2: '',
  uf: '',
  status: []
}

export default {
  name: 'Clientes',
  mixins: [DefaultMixin, GlobalPersonMixin],
  components: {
    ContextMenuClienteItem,
    // UfSelect,
    PlanoSelect,
    DateInput,
    StatusSelect,
    SearchFilter,
    DefaultListMenu,
    MenuOptions,
    StatusCliente,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField
  },
  data () {
    return {
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: [
          {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true},
          {
            name: 'nome',
            required: true,
            label: 'Nome',
            align: 'left',
            field: 'nome',
            sortable: true
          },
          {
            name: 'dominio',
            required: true,
            label: 'Dominio',
            align: 'left',
            field: 'dominio',
            sortable: true
          },
          {
            name: 'plano',
            required: false,
            label: 'plano',
            align: 'left',
            field: 'plano',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    visibledColumns () {
      return ['id', 'nome', 'dominio', 'instancia', 'plano', 'status', 'options']
    },
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    request ({pagination, filter}) {

      let data1 = ''
      let data2 = ''
      let extraFilters = []
      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        extraFilters.push(`&data1=${data1}`)
        extraFilters.push(`&data2=${data2}`)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
      }/* else {
        let substract = Number(this.filtros.tipoFiltroData)
        let newDate = new Date()
        newDate = date.subtractFromDate(newDate, {days: substract})
        data1 = date.formatDate(newDate, 'YYYY-MM-DD')
        data2 = date.formatDate(new Date(), 'YYYY-MM-DD')
      }*/

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.plano && extraFilters.push(`&plano=${this.table.filters.plano}`)
      this.table.filters.uf && extraFilters.push(`&uf=${this.table.filters.uf}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)

      let documento = String(this.table.filters.documento).replace(/\D/gi, '') // TODO: Only numbers

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${this.table.filters.search}&documento=${documento}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          this.table.serverData = data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.clienteWindow()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    abrir (id) {
      console.log('Abrir window cliente')
      this.clienteWindow(id)
    },
    clienteWindow: clienteWindow
  },
  meta: {
    title: 'Clientes',
    name: 'Clientes'
  }
}
</script>

<template>
  <layout class="erp-list layout-dark" no-menu menu-style="compact" menu-active="clientes">
    <div class="mw-1200">
      <search-filter>
        <form @keydown.enter="pesquisar" id="clienteFilter" class="m-t-sm">
          <div class="row erp-row def-m-r">

            <div class="col" style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Código"
              >
                <erp-input shortkey="F3" v-model="table.filters.id" />
              </erp-s-field>
            </div>

            <div class="col" style="min-width: 126px; max-width: 126px">
              <plano-select select-null v-model="table.filters.plano" />
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Nome/Email/Telefone"
              >
                <erp-input shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Documento"
              >
                <erp-input shortkey="F4" v-model="table.filters.documento" />
              </erp-s-field>
            </div>

            <div class="col" style="min-width: 146px; max-width: 146px">
              <date-input label="Data de cadastro (De)" v-model="table.filters.data1" />
            </div>

            <div class="col" style="min-width: 146px; max-width: 146px">
              <date-input label="Data de cadastro (Até)" v-model="table.filters.data2" />
            </div>

<!--            <div class="col">
              <uf-select v-model="table.filters.uf" />
            </div>-->

            <div class="col">
              <status-select multiple v-model="table.filters.status" />
            </div>
          </div>

          <div class="row erp-row">
            <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column">
                <u-btn @click="novo" class="cadastrar bg-positive" label="Cadastrar" v-shortkey="['shift', 'n']"
                       @shortkey.native="novo" />
                <span class="shortcut">Shift + N</span>
              </div>
            </div>
          </div>
        </form>
      </search-filter>
      <div class="erp-list">
        <u-table
            dark
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th :style="col.name === 'id' ? ' max-width: 25px' : ''" v-for="col in props.cols" :key="col.name"
                  :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td style="max-width: 25px" key="id" :props="props">
              {{props.row.id}}
            </u-td>
            <u-td key="nome"
                  :props="props">
              <a @click="gotoPerson(props.row.pessoa)">{{props.row.pessoa.name}}</a>
            </u-td>
            <u-td key="dominio" :props="props">
              {{props.row.dominio}}
            </u-td>
            <u-td key="plano"
                  :props="props">
              {{props.row.plano ? props.row.plano.nome : '-'}}
            </u-td>
            <u-td class="text-uppercase" key="status" :props="props">
              <status-cliente :cliente="props.row" />
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" :leilao="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-cliente-item @abrir="abrir(props.row.id)" :leilao="props.row" />
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
