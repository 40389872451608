export default {
  title: 'Clientes',
  menu: [
    {
      id: 'clientes',
      title: 'Clientes',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/clientes'
    }
  ]
}
